type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'; // Define the HTTP methods type

interface Endpoint {
  [key: string]: [HttpMethod, string]; // Define an endpoint object with keys as string and values as tuple of HttpMethod and string
}

const endpoints: Endpoint = {
  "auth:user:search": ['GET', '/user'],

  "company:search": ['GET', '/companies'],
  "company:get": ['GET', '/companies/:id'],
  "company:create": ['POST', '/companies'],
  "company:update": ['PUT', '/companies/:id'],

  "customer:search": ['GET', '/customers'],
  "customer:get": ['GET', '/customers/:id'],
  "customer:create": ['POST', '/customers'],
  "customer:update": ['PUT', '/customers/:id'],
  "customer:delete": ['DELETE', '/customers/:id'],
  "customer:delete:validate": ['GET', '/customers/:id/delete-validate'],
  "customer:hierarchy:get": ['GET', '/customers/:id/hierarchy'],
  "customer:reset:info": ['PUT', '/customers/:id/reset-info'],

  "currency:search": ['GET', '/currencies'],

  "timezone:search": ['GET', '/timezones'],

  "setting:search": ['GET', '/settings'],
  "setting:get:tx-time-limit": ['GET', '/settings/take-time'],

  "user:search": ['GET', '/users'], // this only using for manage User(Customer Service) for assign user company using
  "user:get": ['GET', '/users/:id'],
  "user:update": ['PUT', '/users/:id'],

  "game:platform:search": ['GET', '/game/platform/accounts'],
  "game:platform:get": ['GET', '/game/platform/accounts/:id'],
  "game:platform:create": ['POST', '/game/platform/accounts'],
  "game:platform:update": ['PUT', '/game/platform/accounts/:id'],

  "game:customer:search": ['GET', '/game/customer/accounts'],
  "game:customer:get": ['GET', '/game/customer/accounts/:id'],
  "game:customer:create": ['POST', '/game/customer/accounts'],
  "game:customer:update": ['PUT', '/game/customer/accounts/:id'],
  "game:customer:delete": ['DELETE', '/game/customer/accounts/:id'],

  "game:provider:search": ['GET', '/game/providers'],
  "game:provider:create": ['POST', '/game/providers'],
  "game:provider:update": ['PUT', '/game/providers/:id'],

  "payment:platform:provider:search": ['GET', '/payment/platform/providers'],
  "payment:platform:provider:create": ['POST', '/payment/platform/providers'],
  "payment:platform:provider:update": ['PUT', '/payment/platform/providers/:id'],

  "payment:platform:account:search": ['GET', '/payment/platform/accounts'],
  "payment:platform:account:create": ['POST', '/payment/platform/accounts'],
  "payment:platform:account:update": ['PUT', '/payment/platform/accounts/:id'],
  "payment:platform:account:delete": ['DELETE', '/payment/platform/accounts/:id'],
  "payment:platform:account:get": ['GET', '/payment/platform/accounts/:id'],

  "payment:customer:provider:search": ['GET', '/payment/customer/providers'],
  "payment:customer:provider:create": ['POST', '/payment/customer/providers'],
  "payment:customer:provider:update": ['PUT', '/payment/customer/providers/:id'],

  "payment:customer:account:search": ['GET', '/payment/customer/accounts'],

  "bonus:search": ['GET', '/bonuses'],
  "bonus:get": ['GET', '/bonuses/:id'],
  "bonus:create": ['POST', '/bonuses'],
  "bonus:update": ['PUT', '/bonuses/:id'],

  "rebate:search": ['GET', '/rebates'],
  "rebate:get": ['GET', '/rebates/:id'],
  "rebate:config:update": ['PUT', '/rebates/:id/config'],
  "rebate:recalculate": ['POST', '/rebates/:id/recalculate'],

  "rebate:daily-turnover:search": ['GET', '/rebates/daily-turnover/search'],
  "rebate:daily-turnover:payout": ['POST', '/rebates/daily-turnover/payout'],
  "rebate:daily-turnover:payout-rollback": ['POST', '/rebates/daily-turnover/payout-rollback'],
  "rebate:daily-turnover:reject": ['POST', '/rebates/daily-turnover/reject'],
  "rebate:daily-turnover:reject-rollback": ['POST', '/rebates/daily-turnover/reject-rollback'],

  "rebate:monthly-lose:search": ['GET', '/rebates/monthly-lose/search'],
  "rebate:monthly-lose:payout": ['POST', '/rebates/monthly-lose/payout'],
  "rebate:monthly-lose:payout-rollback": ['POST', '/rebates/monthly-lose/payout-rollback'],
  "rebate:monthly-lose:reject": ['POST', '/rebates/monthly-lose/reject'],
  "rebate:monthly-lose:reject-rollback": ['POST', '/rebates/monthly-lose/reject-rollback'],

  "rebate:referral-monthly-turnover:search": ['GET', '/rebates/referral-monthly-turnover/search'],
  "rebate:referral-monthly-turnover:payout": ['POST', '/rebates/referral-monthly-turnover/payout'],
  "rebate:referral-monthly-turnover:payout-rollback": ['POST', '/rebates/referral-monthly-turnover/payout-rollback'],
  "rebate:referral-monthly-turnover:reject": ['POST', '/rebates/referral-monthly-turnover/reject'],
  "rebate:referral-monthly-turnover:reject-rollback": ['POST', '/rebates/referral-monthly-turnover/reject-rollback'],

  "rebate:referral-monthly-lose:search": ['GET', '/rebates/referral-monthly-lose/search'],
  "rebate:referral-monthly-lose:payout": ['POST', '/rebates/referral-monthly-lose/payout'],
  "rebate:referral-monthly-lose:payout-rollback": ['POST', '/rebates/referral-monthly-lose/payout-rollback'],
  "rebate:referral-monthly-lose:reject": ['POST', '/rebates/referral-monthly-lose/reject'],
  "rebate:referral-monthly-lose:reject-rollback": ['POST', '/rebates/referral-monthly-lose/reject-rollback'],

  "transaction:search": ['GET', '/transactions'],
  "transaction:get": ['GET', '/transactions/:id'],
  "transaction:create": ['POST', '/transactions'],
  "transaction:update": ['PUT', '/transactions/:id'],
  "transaction:delete": ['DELETE', '/transactions/:id'],
  "transaction:delete:multiple": ['DELETE', '/transactions'],
  "transaction:customer-service:search": ['GET', '/transactions/customer-service'],
  "transaction:heat-map": ['GET', '/transactions/heat-map'],
  "transaction:update:check": ['PUT', '/transactions/:id/check'],

  "account:search": ['GET', '/account/accounts'],
  "statement:search": ['GET', '/account/statements'],
  "statement:bfcf": ['GET', '/account/statements/bfcf'],
  "statement:balance": ['GET', '/account/statements/balance'],

  "report:daily": ['GET', '/report/daily'],
  "report:sales": ['GET', '/report/sales'],
  "report:customer": ['GET', '/report/customer'],
  "report:game": ['GET', '/report/game'],
  "report:bank": ['GET', '/report/bank'],
  "report:bonus": ['GET', '/report/bonus'],
  "report:rebate": ['GET', '/report/rebate'],
  "report:commission": ['GET', '/report/commission'],

  "commission:suggestion": ['POST', '/commission/suggestion'],

  "follow:up:create": ['POST', '/follow/up'],
  "follow:up:update": ['PUT', '/follow/up/:id'],
  // "follow:up:delete": ['DELETE', '/follow/up/:id'],
  "follow:up:search": ['GET', '/follow/up'],
  // "follow:up:get": ['GET', '/follow/up/:id'],

  "follow:reminder:set": ['POST', '/follow/reminder/set'],
  "follow:reminder:unset": ['DELETE', '/follow/reminder/unset'],
  "follow:reminder:search": ['GET', '/follow/reminder'],
  "follow:reminder:customer": ['GET', '/follow/reminder/customer'],

  "follow:short:search": ['GET', '/follow/short'],

  "entry:game-data:create": ['POST', '/entry/game-data'],
  "entry:game-data:update": ['PUT', '/entry/game-data/:id'],
  "entry:game-data:delete": ['DELETE', '/entry/game-data'],
  "entry:game-data:search": ['GET', '/entry/game-data'],

  "roster:create": ['POST', '/roster'],
  "roster:update": ['PUT', '/roster'],
  "roster:search": ['GET', '/roster'],

  "unclaim:search": ['GET', '/unclaims'],
  "unclaim:update": ['PUT', '/transactions/:id/deposit-claim'],

  "log:office:search": ['GET', '/log/office'],
  "log:office:endpoints": ['GET', '/log/office/endpoints'],

  "log:transaction:search": ['GET', '/log/transaction'],

  "public:user:name:get": ['GET', '/public/users/name'],
};

export default endpoints;